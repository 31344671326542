import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Vanity from "./Vanity.js";
import GoOutside from "./GoOutside.js";
import Resume from "./resume";

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Vanity/>}/>
            <Route path="/go-outside" element={<GoOutside/>}/>
            <Route path="/resume" element={<Resume/>}/>
          </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
