import React, { Component } from 'react';
import encephalon from './encephalon.png';
import './App.css';

class Vanity extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="Vanity">
        <div className="Vanity-section">
          <img className="Vanity-img" src={encephalon} alt="distressed blob creature with googly eyes in high-tech glass pod" />
          <div className="Vanity-linklist">
            <Link href="https://itsybitesyspider.bandcamp.com/">Bandcamp</Link>
            <Link href="https://www.github.com/itsybitesyspider">Github</Link>
            <Link href="https://www.goodreads.com/review/list/35286264-christopher-hinson?shelf=read&sort=rating">Goodreads</Link>
            <Link href="./go-outside">Go Outside</Link>
            <Link href="https://anachronidia.itch.io/">itch.io</Link>
          </div>
        </div>
      </div>
    );
  }
}

function Link(props) {
  return (
    <p><a
      className="Vanity-link"
      href = {props.href}
      rel="noopener noreferrer"
    >
      {props.children}
    </a></p>
  );
}

export default Vanity;
