import React from 'react';
import './index.css';

function Soft(props) {
  return (
    <span className="soft">{props.children}</span>
  );
}

function Sheet(props) {
  return (
    <div className="Theme">
      <div className="Sheet">
        <header className="Section">
          <p className="Contact">
            <div className="Contact-block">
              <span className="Contact-item">Christopher Lane Hinson</span>
              <span className="Contact-item">(Software Developer)</span>
            </div>
            <div className="Contact-block">
              <span className="Contact-item">p: (xxx)-xxx-xxxx</span>
              <span className="Contact-item">e: clanehin@gmail.com</span>
            </div>
          </p>
        </header>

        <hr/>

        <div className="Section">
        <div className="Section-workplace">
            <p className="Section-title"><strong>Senior Software Development Engineer at Metabolon (March 2022 - Present)</strong></p>
            <div className="Accomplishments">
              <ul>
                <li>Developed a proof-of-concept WebView implementation of an underperforming UI component.<Soft>Using WebView2, react, typescript, and D3.js.</Soft></li>
                <li>Conducted Sprint Retrospectives.<Soft>Promoted healthy communication styles during a stressful transition to more rigorous software development processes and a new management structure.</Soft></li>
              </ul>
            </div>
            <p className="Section-title"><strong>Software Development Engineer II at Metabolon (Apr 2021 - March 2022)</strong></p>
            <div className="Accomplishments">
              <ul>
                <li>In collaboration with domain specialists, built front-end and back-end tools used to interpret data from LCMS (liquid chromatography / mass spectrometry) instruments.<Soft>Involving C#, ASP.NET Core, and Windows desktop application devopment.</Soft></li>
                <li>Mentored onboarding team members.</li>
              </ul>
            </div>
          </div>

          <div className="Section-workplace">
            <p className="Section-title"><strong>Software Developer at CargoSphere (Jan 2020 - Jan 2021)</strong></p>
            <div className="Accomplishments">
              <ul>
                <li>Maintained and extended a regression test tool, based on spring boot, to ensure accuracy of business results. <Soft>Automated identification of new defects within first month after hiring.</Soft></li>
                <li>As part of a team, participated in a bug-fixing campaign for a major release of the admin component of the core product. <Soft>Involving docker-compose, bash shell scripting, spring-boot, angular, PostgreSQL, legacy java servlet pages, and golang.</Soft></li>
                <li>Refactored multiple web services to support a new authentication mechanism.</li>
                <li>Supported transition of the test suite for our flagship product to a new RESTful JSON API and retiring of the old API.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="Section">
          <div className="Section-workplace">
            <p className="Section-title"><strong>Sabatical (Apr 2018 - Dec 2019)</strong></p>
            <div className="Accomplishments">
              <ul>
                <li>Various experiments with rust and WebAssembly.</li>
                <li>Various experiments with computer graphics, especially on the web with three.js and WebGL.</li>
                <li>Built <a href="https://github.com/itsybitesyspider/retriever">retriever</a>, a fast in-memory indexed data store.</li>
                <li>Built <a href="https://anachronidia.itch.io/time-spiders">Time Spiders</a>, a game project, initially using electron and react.js.</li>
                <li>Introduced myself to other interesting topics such as machine learning and quantum computing.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="Section">
          <div className="Section-workplace">
            <p className="Section-title"><strong>Software Developer at Web Performance, Inc (Aug 2010 - Mar 2018)</strong></p>
            <div className="Accomplishments">
              <ul>
                <li>Maintained and enhanced flagship 250 kloc Java application and smaller customer-facing web portal. <Soft>Included desktop, front-end and back-end components.</Soft></li>
                <li>Improved scalability of our AWS EC2 controller by a factor of 10. <Soft>Allowed us to take on larger, more profitable clients.</Soft></li>
                <li>On my own initiative, proposed and developed a novel stress test tool. <Soft>Within three months, we were able to accept a client substantially outside our previous market niche.</Soft></li>
                <li>Implemented a rules-based pattern-matching system that reduced manual labor during services work. <Soft>Reduced costs and increased business throughput.</Soft></li>
                <li>Developed a graphical editor for a next-generation version of our flagship product. <Soft>A substantial new user-facing feature that empowered us to take on clients we would have previously declined.</Soft></li>
                <li>Provided direct services for startups, large corporations, non-profits, and government agencies. <Soft>Responsible for satisfying all client’s needs, from SOW to final report. Focus on finding and explaining technical and business risks faced by the client.</Soft></li>
                <li>Mitigated technical debt and advocated for best practices. Recognized and reported security risks when encountered.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="Technologies">
          Technologies:
          <ul>
            <li>Major Languages: C#, Java, JavaScript, Rust</li>
            <li>Less or less recent practice with: Haskell, C/C++</li>
            <li>Frontend: react.js, three.js, WebAssembly</li>
            <li>Backend: ASP.NET Core, express.js (node.js), Spring Boot, wicket, </li>
            <li>Database: PostgreSQL, CouchDB</li>
            <li>Desktop GUI: windows, electron, Eclipse/SWT</li>
            <li>Cloud: Amazon Web Services (AWS) and Google App Engine</li>
            <li>Graphics: WebGPU, three.js, GLSL</li>
            <li>Tools: docker, git, lerna</li>
            <li>Mobile: Some exposure to Android (Java), React Native</li>
            <li>Security: JSON Web Tokens, Browser certificate management</li>
            <li>Experience troubleshooting load-related scalability issues in HTTP services.</li>
          </ul>
        </div>

        <div className="Section">
          <div className="Section-workplace">
            <p className="Section-title"><strong>Portfolio, Examples, GitHub, etc</strong></p>
              <p><span className="Emoji">&#128021;</span> <a href="https://github.com/itsybitesyspider/retriever">retriever</a>: a fast in-memory indexed data store supporting map-reduce operations.</p>
              <p><span className="Emoji">&#9928;</span> <a href="https://reimplied.info/go-outside">go-outside</a> (currently broken, thanks to a new and improved API from the national weather service): a react app to encourage me to go outside during good weather.</p>
              <p><span className="Emoji">&#128375;</span> <a href="https://anachronidia.itch.io/time-spiders">Time Spiders</a>: a game project.</p>
              <p><span className="Emoji">&#128073;</span> This resume is a react app at <a href="https://reimplied.info/resume">https://reimplied.info/resume</a>. It's served from the AWS CDN and supports dark mode.</p>
          </div>
        </div>

        <div className="Section">
          <div className="Section-workplace">
            <p className="Section-title"><strong>Education</strong></p>

            <p>Attended North Carolina State University 2000-2006. Double-Major in Computer Science & Math Education</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sheet;
