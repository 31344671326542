import React, { Component } from 'react';
import './App.css';

class GoOutside extends Component {
  constructor() {
    super();
    this.state = {
      errs: [],
    };

    this.checkGoOutside();
  }

  async checkGoOutside() {
    this.setState({ getting_current_location: "maybe" });
    await Promise.resolve();
    if("geolocation" in navigator) {
      await Promise.resolve();
      this.setState({ getting_current_location: true });
      await Promise.resolve();
      navigator.geolocation.getCurrentPosition(async (success) => {
        this.setState({ getting_current_location: false });
        await Promise.resolve();
        await this.checkGoOutsideWithCoords({
          lat: success.coords.latitude,
          lon: success.coords.longitude
        });
      }, async (failure) => {
        this.setState({ getting_current_location: false });
        this.setState(s => s.errs.push(failure.message));
      });
    } else {
      this.setState(s => s.errs.push("no geolocation API available"));
      this.setState({ getting_current_location: false });
    }
  }

  async checkGoOutsideWithCoords(coords) {
    try {
      this.setState({ fetching_weather_forecast: true });

      await Promise.resolve();

      const res = await fetch("https://him22be620.execute-api.us-west-2.amazonaws.com/demo", {
        method: "POST",
        body: JSON.stringify({
          lat: coords.lat,
          lon: coords.lon
        })
      });

      if( !res.ok ) {
         this.setState(s => s.errs.push("bad response: " + res));
        return;
      }

      const go_outside = await res.json();
      this.setState({ go_outside });
      this.finishedGoOutside(go_outside);
    } catch( e ) {
      this.setState(s => s.errs.push(e.message));
    } finally {
      this.setState({ fetching_weather_forecast: false })
    }
  }

  finishedGoOutside(go_outside) {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get("redirect");

    if( redirect && !go_outside.answer ) {
      window.location.href = redirect;
    }
  }

  render() {
    return (
      <div className="Vanity"><div className="Vanity-section"><div className="Vanity-weather">
      { this.state.getting_current_location && (
        <div>
          <p>Discovering your location...</p>
          <p>Make sure to allow location in your browser.</p>
        </div>
      ) }

      { this.state.fetching_weather_forecast && (
        <div>
          <p>Fetching your local weather forecast...</p>
        </div>
      ) }

      { this.state.go_outside && this.state.go_outside.answer && (
        <div>
          <h3>Have you considered going outside?</h3>
          <p>You won't see weather this good for a while.</p>
        </div>
      ) }

      { this.state.go_outside && !this.state.go_outside.answer && (
        <div>
          <p>The ideal weather this week will be:</p>
        </div>
      ) }
        { this.state.go_outside && (<p><strong>{this.state.go_outside.best}</strong></p>) }

      <div class="Vanity-spacer"/>
      <p class="tip">I wrote this app because I live in an area where the weather varies wildly from day to day.</p>
      <p class="tip">It will tell you when in the upcomming week offers the most favorable meteorological conditions.</p>
      <p class="tip">Currently uses the United States Weather Service, so it's US-only. <span role="img" aria-label="Crying Face Emoji">😢</span></p>
      <p class="tip">Tip: Use ?redirect=http://www.example.com to make this page automatically redirect to your favorite homepage, however it will gently block you when the weather is particularly good.</p>

      {this.state.errs.map(err => <p class="Vanity-err">{err}</p>)}
      </div></div></div>
    );
  }
}

export default GoOutside;
